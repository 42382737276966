var swipe = {
	init: function () {

		var swiperGallery = new Swiper('.swiper-container.gallery_slide', {
			loop: true,
			autoplay: {
				delay: 5000,
			},
			// slidesPerView: "auto",
			// centeredSlides: true,
			// spaceBetween: 10,
			lazy: true,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			// Default parameters for smallest screen
			slidesPerView: 1,
			spaceBetween: 0,
			// Responsive breakpoints
			breakpointsInverse: true,
			breakpoints: {
				// when window width is >= 1024px
				1024: {
					slidesPerView: "auto",
				  	spaceBetween: 20,
					centeredSlides: true,
				}
			}
		});

		var swiperClients = new Swiper('.swiper-container.clients_slide', {
			autoplay: {
				delay: 5000,
			},
			loop: true,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			// Default parameters for smallest screen
			slidesPerView: 1,
		});

		var swiperUser = new Swiper('.swiper-container.slide-user', {
			loop: true,
			autoplay: {
				delay: 5000,
			},
			spaceBetween: 10,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			// Default parameters for smallest screen
			slidesPerView: 1,
			breakpointsInverse: true,
			breakpoints: {
				768: {
				  slidesPerView: 2
				},
				1024: {
				  slidesPerView: 3
				}
			}
		});		

		var swiperApart01 = new Swiper('.swiper-container.slide-habitaciones01', {
			autoplay: {
				delay: 5000,
			},
			loop: true,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			// Default parameters for smallest screen
			slidesPerView: 1,
		});		

		var swiperApart02 = new Swiper('.swiper-container.slide-habitaciones02', {
			autoplay: {
				delay: 5000,
			},
			loop: true,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			// Default parameters for smallest screen
			slidesPerView: 1,
		});		

		var swiperApart03 = new Swiper('.swiper-container.slide-habitaciones03', {
			autoplay: {
				delay: 5000,
			},
			loop: true,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			// Default parameters for smallest screen
			slidesPerView: 1,
		});		

		var swiperApart04 = new Swiper('.swiper-container.slide-habitaciones04', {
			autoplay: {
				delay: 5000,
			},
			loop: true,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			// Default parameters for smallest screen
			slidesPerView: 1,
		});		

		var swiperApart05 = new Swiper('.swiper-container.slide-habitaciones05', {
			autoplay: {
				delay: 5000,
			},
			loop: true,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			// Default parameters for smallest screen
			slidesPerView: 1,
		});		

		var swiperApart06 = new Swiper('.swiper-container.slide-habitaciones06', {
			autoplay: {
				delay: 5000,
			},
			loop: true,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			// Default parameters for smallest screen
			slidesPerView: 1,
		});		
	}
};

swipe.init();