var togglers = {
	$body: $('body'),
	$header: $('.header'),
	$toggler: $('.toggler'),
	// $menumenu: $('.menu'),
	// $menuhotel: $('.hotels'),
	
	init: function () {
		this.bindEvents();
	},

	bindEvents: function() {
		var self = this;
		this.$toggler.on('click', function(e) {
			e.preventDefault();
			self.onClickedToggler(this);
		});
	},

	onClickedToggler: function(el) {
		if ($(el).hasClass('header__toggle') && this.$body.hasClass('open-menu')) {
			var section = $(el).data('show-section');
			this.closeSection(section);
			console.log(section);
		} else if ($(el).hasClass('header__toggle-hotels') && this.$body.hasClass('open-menu-h')) {
			var section = $(el).data('show-section');
			this.closeSection(section);
			console.log(section);
		} else {
			var section = $(el).data('show-section');
			this.openSection(section);
			console.log(section);
		}
	},

	openSection: function(el) {
		if ($(el).hasClass('hotels')) {
			// this.$header.addClass('is-active');
			this.$body.addClass('open-menu-h').removeClass('open-menu')
			$(el).addClass('is-visible');
		} else if ($(el).hasClass('menu-hotels')) {
			// this.$header.addClass('is-active');
			this.$body.addClass('open-menu-h').removeClass('open-menu')
			$(el).addClass('is-visible');
		} else {
			this.$body.addClass('open-menu').removeClass('open-menu-h')
			this.$header.addClass('is-active');
			$(el).addClass('is-visible');
			$('.hotels.is-visible').removeClass('is-visible');
		}
	},

	closeSection: function (el) {
		// this.$header.removeClass('is-active');

		if ($(el).hasClass('hotels')) {
			// this.$header.addClass('is-active');
			this.$body.removeClass('open-menu-h')
			$(el).removeClass('is-visible');
		} else if ($(el).hasClass('menu')) {
			this.$body.removeClass('open-menu')
			this.$header.removeClass('is-active');
			$(el).removeClass('is-visible');
		}

		// $(el).removeClass('is-visible');
		// $('.is-menu.is-visible').removeClass('is-visible');
	}
};

var fixMenu = {
	init: function () {

		$(window).bind('scroll', function () {
		    if ($(window).scrollTop() > 80) {
		    	$('header.header').addClass('fixed');
				$('.menu.is-menu').addClass('fixed');
		    } else {
		    	$('header.header').removeClass('fixed');
		    	$('.menu.is-menu').removeClass('fixed');
		    }
		});		
	}
};

var buttonReserva = {
	
	init: function () {

		$(window).bind('scroll', function () {
		if ($(window).scrollTop() > 200) {
			$('.button-reservar').addClass('show');
		} else {
			$('.button-reservar').removeClass('show');
		}
		});		
	}
};

//fixMenu.init();
togglers.init();
buttonReserva.init();